import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./App.css";
import Loading from "./componets/loading";
import colors from "./colors";
import { HashRouter, Route, Routes } from "react-router-dom";
import BottomNavBar from "./componets/bottomNavBar";
import axios from "axios";
import { auth } from "./firebase";
import { signInWithCustomToken } from "firebase/auth";
import Onboarding from "./pages/onboarding/startOnboarding";
import { apiUrl } from "./utils/utils";
import { UserResponse } from "./types/basetype";
import ProfilePage from "./pages/profile/profilePage";
import GamesPage from "./pages/home/games";
import { Space } from "./componets/baseComp";

function App() {
  const [state, setState] = useState("loading");
  const [userData, setUserData]: [any, Dispatch<SetStateAction<any>>] =
    useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setState("loading");
      if (user) {
        setState("authenticated");
        getBaseData();
      } else {
        loginWithTelegram();
      }
    });

    return () => unsubscribe();
  }, []);

  const loginWithTelegram = async () => {
    try {
      const fakeInitData = {
        user: JSON.stringify({
          id: 123456789,
          is_bot: false,
          first_name: "John",
          last_name: "Doe",
          username: "johndoe",
          photo_url: "http://example.com/photo.jpg",
        }),
        chat: JSON.stringify({
          id: 987654321,
          type: "private",
        }),
        hash: "abcdef1234567890",
      };

      const response = await axios.post(apiUrl + "/auth/login", {
        telegramInitData: fakeInitData,
      });

      const data = response.data;

      if (data.token) {
        await signInWithCustomToken(auth, data.token);
        setState("authenticated");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setState("error");
    }
  };

  const getBaseData = async () => {
    setState("loading");
    const idToken = await auth.currentUser?.getIdToken();

    const userDataRes = await axios.get(apiUrl + "/auth/user", {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    const userData: UserResponse = userDataRes.data;
    console.log(userData);
    setUserData(userData);

    if (!userData.walletConnected || !userData.hasCommunity) {
      setState("onboarding");
      return;
    }
    setState("home");
    //get data, check wallet
  };

  if (state === "loading") {
    return (
      <div
        className="flex items-center justify-center h-screen"
        style={{ background: colors.gradient }}
      >
        <Loading />
      </div>
    );
  }

  if (state === "onboarding") {
    return <Onboarding userData={userData!} />;
  }

  return (
    <div
      style={{ background: colors.gradient, width: "100%", height: "100vh" }}
    >
      <HashRouter>
        <Routes>
          <Route path="/" element={<GamesPage />} />
          <Route
            path="/profile"
            element={<ProfilePage userData={userData} />}
          />
        </Routes>
        <Space height="120px" />
        <BottomNavBar />
      </HashRouter>
    </div>
  );
}

export default App;
