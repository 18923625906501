import { useEffect, useState } from "react";
import {
  Button,
  Description,
  RColumn,
  Space,
  Title,
} from "../../componets/baseComp";
import { BrowserProvider } from "ethers";
import {
  createWeb3Modal,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import axios from "axios";
import { apiUrl } from "../../utils/utils";
import Loading from "../../componets/loading";
import { LuAlertTriangle } from "react-icons/lu";
import { auth } from "../../firebase";

// Configurazione della chain (Ethereum mainnet)
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

// Creazione di Web3Modal con configurazione del progetto
createWeb3Modal({
  projectId: "27188540ce1b5a4efdd94723937c8230",
  chains: [mainnet],
  ethersConfig: {
    metadata: {
      url: "http://localhost:3000/",
      description: "Token Rivals",
      icons: [],
      name: "Token Rivals",
    },
  },
  enableAnalytics: false,
});

export function ConnectWallet({ onConnected }: { onConnected: () => void }) {
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, address } = useWeb3ModalAccount();

  const [state, setState] = useState("init");

  const [canSign, setCanSign] = useState(false);

  async function sign() {
    if (walletProvider) {
      try {
        setState("loading");
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();

        const message =
          "Please sign this message to confirm your identity for TokenRivals.";
        const signature = await signer.signMessage(message);
        const idToken = await auth.currentUser?.getIdToken();

        await axios.post(
          apiUrl + "/web3/addwallet",
          {
            address: address,
            signedMsg: signature,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        onConnected();
      } catch (e) {
        console.log(e);
        setState("error");
      }
    } else {
      console.error("Wallet provider not available.");
      setState("error");
    }
  }
  async function connect() {
    setCanSign(true);
    await open();
  }

  useEffect(() => {
    if (isConnected && canSign) sign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, canSign]);

  return (
    <RColumn className="w-full px-3 justify-between h-screen py-10">
      <div>
        <Title className="text-center">Connect Wallet</Title>
        <Space />
        <Description className="text-center">
          Connect your wallet and sign to verify your identity.
        </Description>
      </div>
      {state !== "init" && (
        <div className="w-full flex justify-center">
          {state === "loading" && <Loading />}
          {state === "error" && (
            <RColumn className="items-center">
              <LuAlertTriangle size={42} color="red" />
              <Space />
              <Description>There was an error.</Description>
            </RColumn>
          )}
        </div>
      )}
      <div className="w-full">
        {!isConnected && (
          <Button className="w-full" onClick={connect}>
            Connect & Sign
          </Button>
        )}
        {isConnected && (
          <div className="w-full flex flex-col justify-center items-center">
            <Button className="w-full" onClick={sign}>
              Sign
            </Button>
            <Space />
            {/*   <RRow className="justify-between items-center">
              <Description className="text-center w-[50%]">
                {address?.substring(0, 12)}
              </Description>
              <Button onClick={close} className="w-[50%]">
                Sign out
              </Button>
            </RRow> */}
            <w3m-account-button />
          </div>
        )}
      </div>
    </RColumn>
  );
}
