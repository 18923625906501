import { Link } from "react-router-dom";
import colors from "../colors";
import { LuGamepad2, LuTrophy, LuUser } from "react-icons/lu";
import { IconType } from "react-icons";

export default function BottomNavBar() {
  function SingleBtn({
    link,
    tit,
    Icon,
  }: {
    link: string;
    tit: string;
    Icon: IconType;
  }) {
    return (
      <div className="py-3">
        <Link to={link} className="flex-1 text-center">
          <Icon color="white" size={26} />
        </Link>
      </div>
    );
  }

  return (
    <div
      className={`fixed bottom-3 left-0 right-0 mx-3 px-2 ${colors.glassBackgroundClass} rounded-2xl`}
    >
      <div className="flex flex-row justify-evenly items-center">
        <SingleBtn link="/lead" tit="Lead" Icon={LuTrophy} />
        <SingleBtn link="/" tit="Games" Icon={LuGamepad2} />
        <SingleBtn link="/profile" tit="Profile" Icon={LuUser} />
      </div>
    </div>
  );
}
