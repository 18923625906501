// src/styles/colors.ts

const colors = {
    gradient: 'linear-gradient(to bottom, #0A1646, #131313)',
    primary: '#6333F7',
    secondary: '#5E45DD',
    tertiary: '#1e2c75',
    glassBackgroundClass: 'glass-background'
};

export default colors;
