// src/components/Button.tsx
import React from "react";
import colors from "../colors";
import { Title3 } from "./baseComp";

interface ButtonProps {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

export const PrimaryButton: React.FC<ButtonProps> = ({
  className = "",
  onClick,
  children,
}) => (
  <button
    onClick={onClick}
    className={`px-6 py-2 hover:opacity-90 ${className} rounded-lg`}
    style={{ backgroundColor: colors.primary }}
  >
    <Title3>{children}</Title3>
  </button>
);

export const GradientButton: React.FC<ButtonProps> = ({
  className = "",
  onClick,
  children,
}) => (
  <button
    onClick={onClick}
    className={`px-6 py-2 hover:opacity-90 ${className} rounded-lg glass-background-color-btn`}
  >
    <Title3>{children}</Title3>
  </button>
);
