import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Description,
  RColumn,
  RRow,
  Space,
  Title,
  Title2,
  Title3,
} from "../../componets/baseComp";
import { auth } from "../../firebase";
import axios from "axios";
import { apiUrl } from "../../utils/utils";
import { CommunitiesAvailableRes } from "../../types/basetype";

export function SelectComms({ onSelected }: { onSelected: () => void }) {
  const [availableComms, setAvailableComms] = useState<
    CommunitiesAvailableRes[]
  >([]);

  const [selectedComm, setSelectedComm] = useState("");

  useEffect(() => {
    getComms();
  }, []);

  async function getComms() {
    const idToken = await auth.currentUser?.getIdToken();

    const avComRes = await axios.get(
      apiUrl + "/web3/user-available-communities",
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    const avComms: CommunitiesAvailableRes[] = avComRes.data;
    setAvailableComms(avComms);
    setSelectedComm(avComms[0]?.id);
  }

  async function setComm() {
    const idToken = await auth.currentUser?.getIdToken();

    const r = await axios.post(
      apiUrl + "/web3/select-community",
      {
        community_id: selectedComm,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log(r);
  }

  function handleCommClick(id: string) {
    setSelectedComm(id);
  }

  return (
    <RColumn className="w-full px-3 justify-between h-screen py-10">
      <div>
        <Title className="text-center">This are you communities</Title>
        <Space />
        <Description className="text-center">
          We found those tokens in your wallet, select the one you want to use.
        </Description>
        <Space />
        <div className="gap-3 flex flex-col">
          {availableComms &&
            availableComms.map((comm) => (
              <Container
                selected={selectedComm === comm.id}
                key={comm.id}
                onClick={() => handleCommClick(comm.id)}
              >
                <RRow className="gap-3">
                  <img
                    className="w-12 rounded-lg"
                    src={comm.info?.image}
                    width={"52px"}
                    alt=""
                  />
                  <RColumn>
                    <Title2>{comm.name}</Title2>
                    <RRow className="gap-1 items-center">
                      <Title3
                        className={
                          comm.user_token_amount >= comm.min_token_amount
                            ? "text-green-500"
                            : "text-red-400"
                        }
                      >
                        {comm.user_token_amount}
                      </Title3>
                      <Description>{comm.token_symbol}</Description>
                      <Title3>/</Title3>
                      <Description className="">
                        {comm.min_token_amount}
                      </Description>
                      <Description>{comm.token_symbol}</Description>
                    </RRow>
                  </RColumn>
                </RRow>
              </Container>
            ))}
        </div>
      </div>
      <Button onClick={setComm}>
        Choose {availableComms.find((c) => c.id === selectedComm)?.name}
      </Button>
    </RColumn>
  );
}
