import {
  Badge,
  Description,
  HorizontalScrollContainer,
  RColumn,
  RRow,
  Space,
  Title,
  Title2,
  Title3,
} from "./baseComp";
import { LuTimer } from "react-icons/lu";
import Leaderboard from "./leaderboard";

export default function EventComp({ event_id }: { event_id: string }) {
  return (
    <div className="overflow-y-auto">
      <div className="px-4">
        <RRow className="justify-between items-center">
          <Title>Super Event</Title>
          <RColumn className="items-end">
            <RRow className="items-start">
              <LuTimer size={16} color="white" />
              <div className="w-1" />
              <Description className="text-sm">4h 32m 12s</Description>
            </RRow>
            <Space height="2px" />
            <Badge className="self-center">BUY-IN</Badge>
          </RColumn>
        </RRow>

        <RRow className="items-end gap-1">
          <Title className="text-[#EE9E59]">$1.000</Title>
          <Description>prize pool</Description>
        </RRow>

        <Space />
        <Title2>Games</Title2>
      </div>
      <HorizontalScrollContainer className="py-3">
        <div className="p-3 border border-white rounded-xl w-52">
          <RRow className="items-center gap-2">
            <img src="game_img/crossy_road.png" width={"52px"} alt="" />
            <RColumn>
              <Title3>Crossy road</Title3>
              <Description>Something cool...</Description>
            </RColumn>
          </RRow>
        </div>
        <div className="p-3 border border-white rounded-xl w-52">
          <RRow className="items-center gap-2">
            <img src="game_img/crossy_road.png" width={"52px"} alt="" />
            <RColumn>
              <Title3>Crossy road</Title3>
              <Description>Something cool...</Description>
            </RColumn>
          </RRow>
        </div>
      </HorizontalScrollContainer>
      <Space />
      <Leaderboard
        data={Array.from({ length: 10 }, (_, i) => ({
          rank: i + 1,
          score: (300 - (i + 1)) * 6,
          username: "Ric",
        }))}
      />
    </div>
  );
}
