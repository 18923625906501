import { useEffect, useState } from "react";
import { ConnectWallet } from "./connectWallet";
import { SelectComms } from "./SelectCommunity.tsx";
import { UserResponse } from "../../types/basetype";

export default function Onboarding({ userData }: { userData: UserResponse }) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!userData.walletConnected) setStep(0);
    else setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (step === 0) {
    return <ConnectWallet onConnected={() => setStep(1)} />;
  }
  if (step === 1) {
    return <SelectComms onSelected={() => setStep(2)} />;
  }
  return <>Start</>;
}
