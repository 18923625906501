import { LuGamepad2, LuTimer, LuTrophy, LuX } from "react-icons/lu";
import {
  Badge,
  Container,
  Description,
  HorizontalScrollContainer,
  RColumn,
  RRow,
  Space,
  Title,
  Title2,
  Title3,
} from "../../componets/baseComp";
import { useState } from "react";
import EventComp from "../../componets/eventComp";

export default function GamesPage() {
  const [eventOpened, setEventOpened] = useState(false);

  function ChallengeGame() {
    return (
      <Container
        selected
        className="w-72 max-w-full p-3 my-3"
        onClick={() => setEventOpened(true)}
      >
        <RRow className="w-full justify-between">
          <Title2>Super Event</Title2>
          <RRow className="items-start">
            <LuTimer size={16} color="white" />
            <div className="w-1" />
            <Description className="text-sm">4h 32m 12s</Description>
          </RRow>
        </RRow>
        <Space height="8px" />
        <RRow className="items-end gap-1">
          <Title className="text-yellow-500">$1.000</Title>
          <Description>prize pool</Description>
        </RRow>
        <Space />
        <RRow className="gap-1 items-center">
          <Badge>BUY-IN</Badge>
          <img src="game_img/crossy_road.png" width={"38px"} alt="" />
          <img src="game_img/crossy_road.png" width={"38px"} alt="" />
        </RRow>
      </Container>
    );
  }
  function Game({
    data,
  }: {
    data: { title: string; badge: string; desc: string; img: string };
  }) {
    return (
      <Container>
        <RRow>
          <img src="game_img/crossy_road.png" width={"92px"} alt="" />
          <div className="w-6" />
          <RColumn className="w-full">
            <RRow className="justify-between w-full">
              <Title2>{data.title}</Title2>
              <Badge>{data.badge}</Badge>
            </RRow>
            <Space height="10px" />
            <Description className="text-sm">{data.desc}</Description>
          </RColumn>
        </RRow>
      </Container>
    );
  }
  return (
    <>
      {eventOpened && (
        <div
          onClick={() => setEventOpened(false)}
          className="fixed w-full z-10"
          style={{
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="glass-background animate-slideUp"
            style={{
              width: "100%",
              zIndex: 11,
              position: "absolute",
              borderRadius: "22px 22px 0px 0px",
              height: "98vh",
              bottom: 0,
              overflowY: "auto", // Abilita lo scroll verticale
              overflowX: "hidden", // Evita lo scroll orizzontale
            }}
          >
            <LuX
              color="grey"
              size={22}
              onClick={() => setEventOpened(false)}
              className="mt-2 ml-2"
            />
            <EventComp event_id="test_event" />
          </div>
        </div>
      )}
      <div style={{ backgroundColor: "#251351" }}>
        <RRow className="justify-between items-center p-3">
          <img
            src="https://tokenrivals.com/wp-content/uploads/2024/11/logoTR.png"
            width={"52px"}
            alt=""
          />
          <Title3>JJRic222</Title3>
        </RRow>
      </div>
      <Space height="20px" />
      <RRow className="pl-3 items-center gap-2">
        <LuTrophy size={24} color="orange" />
        <Title2 className="">Events</Title2>
      </RRow>
      <Space height="4px" />
      <HorizontalScrollContainer>
        <ChallengeGame />
        <ChallengeGame />
      </HorizontalScrollContainer>
      <Space />
      <RRow className="pl-3 items-center gap-2">
        <LuGamepad2 size={24} color="orange" />
        <Title2 className="">Games</Title2>
      </RRow>
      <RColumn className="p-3 gap-2">
        {Array.from({ length: 10 }).map((i: any) => (
          <Game
            key={i}
            data={{
              title: "Crossy road",
              badge: "Popular",
              desc: "Super cool and addicted game",
              img: "",
            }}
          />
        ))}
      </RColumn>
    </>
  );
}
