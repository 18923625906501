// src/components/Layout.tsx
import React from "react";
import colors from "../colors";

interface LayoutProps {
  className?: string;
  children: React.ReactNode;
}

export const RRow: React.FC<LayoutProps> = ({ className = "", children }) => (
  <div className={`flex flex-row ${className}`}>{children}</div>
);

export const RColumn: React.FC<LayoutProps> = ({
  className = "",
  children,
}) => <div className={`flex flex-col ${className}`}>{children}</div>;

// src/components/Typography.tsx
interface TextProps {
  className?: string;
  children: React.ReactNode;
}

export const Title: React.FC<TextProps> = ({ className = "", children }) => (
  <h1 className={`text-3xl font-bold text-white ${className}`}>{children}</h1>
);
export const Title2: React.FC<TextProps> = ({ className = "", children }) => (
  <h1 className={`text-xl font-bold text-white ${className}`}>{children}</h1>
);
export const Title3: React.FC<TextProps> = ({ className = "", children }) => (
  <h1 className={`text-base font-bold text-white ${className}`}>{children}</h1>
);
export const Description: React.FC<TextProps> = ({
  className = "",
  children,
}) => <p className={`text-base text-gray-300 ${className}`}>{children}</p>;

interface SpaceProps {
  height?: string; // Height of the space (e.g., '1rem', '16px', '4')
  width?: string; // Width of the space (for horizontal spacing)
}

export const Space: React.FC<SpaceProps> = ({ height, width }) => {
  return (
    <div
      style={{
        height: height || "1rem", // Default to 1rem if no height is provided
        width: width || "100%", // Default to full width if no width is provided
      }}
    />
  );
};
// src/components/Button.tsx
interface ButtonProps {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  className = "",
  onClick,
  children,
}) => (
  <button
    onClick={onClick}
    className={`text-white py-2 px-4 rounded ${colors.glassBackgroundClass} ${className}`}
  >
    {children}
  </button>
);

// src/components/Container.tsx
interface ContainerProps {
  className?: string;
  children: React.ReactNode;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Container: React.FC<ContainerProps> = ({
  className = "",
  children,
  selected = false,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={`p-4 rounded-2xl ${className} ${
      selected ? "glass-background-color" : "glass-background"
    }`}
  >
    {children}
  </div>
);
interface BadgeProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  className = "",
  color = "green",
}) => {
  const backgroundColor = `bg-${color}-500`;
  const borderColor = `border-${color}-400`;

  return (
    <div>
      <div
        className={`inline-flex px-2 py-[2px] rounded-md ${backgroundColor} bg-opacity-40 text-white border ${borderColor} border-opacity-60 items-center gap-1 w-auto ${className}`}
        style={{ fontSize: "13px" }}
      >
        {children}
      </div>
    </div>
  );
};

interface HorizontalScrollContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const HorizontalScrollContainer: React.FC<
  HorizontalScrollContainerProps
> = ({ children, className = "" }) => {
  return (
    <div
      className={`flex overflow-x-auto w-full ${className}`}
      style={{
        whiteSpace: "nowrap", // Prevents line breaks, making it scrollable
        scrollSnapType: "x mandatory", // Enables snap scrolling
      }}
    >
      {React.Children.map(children, (child, index) => (
        <div
          key={index}
          className="inline-block px-2"
          style={{
            scrollSnapAlign: "start", // Aligns items at the start during scroll
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
