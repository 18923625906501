import { LeaderboardEntry } from "../types/basetype";
import { avatarImg } from "../utils/utils";
import {
  Badge,
  Description,
  RColumn,
  RRow,
  Space,
  Title,
  Title3,
} from "./baseComp";

export default function Leaderboard({ data }: { data: LeaderboardEntry[] }) {
  const podiumColors = [
    "bg-gradient-to-b from-gray-400 to-gray-200", // Grigio gradiente
    "bg-gradient-to-b from-yellow-400 to-yellow-200", // Giallo gradiente
    "bg-gradient-to-b from-orange-400 to-orange-200", // Arancione gradiente
  ];
  const podiumHeights = ["230px", "300px", "200px"];

  const top3 = [data[1], data[0], data[2]];
  return (
    <>
      <RRow className="w-full justify-around items-end">
        {top3.map((participant, index) => (
          <div
            key={participant.rank}
            className={`flex flex-col items-center ${podiumColors[index]} rounded-t-xl w-24 mt-20`}
            style={{ height: podiumHeights[index] }}
          >
            <RColumn className="-mt-20 items-center">
              <Badge className="mb-1" color="grey">
                {participant.score} pts
              </Badge>
              <div
                className="w-20 h-20 rounded-full bg-cover bg-center border-4 border-black"
                style={{
                  backgroundImage: `url(${avatarImg(
                    participant.rank.toString()
                  )})`,
                }}
              />
            </RColumn>
            <Space height="8px" />
            <Title className="italic text-4xl">{`${participant.rank}°`}</Title>
          </div>
        ))}
      </RRow>
      <Space />
      <div
        style={{
          backgroundColor: "#0E153D",
          borderRadius: "90px 90px 0px 0px",
          paddingTop: "42px",
          marginTop: "-130px",
          zIndex: 3,
          position: "relative",
        }}
      >
        <RColumn className="gap-3 px-6">
          {data.slice(3).map((entry) => (
            <RRow className="justify-between items-center">
              <RRow className="gap-2 items-center">
                <Description className="w-7">{entry.rank}°</Description>
                <img
                  src={avatarImg(entry.rank.toString())}
                  width={"42px"}
                  alt=""
                  style={{ borderRadius: "100%" }}
                />
                <Description>{entry.username}</Description>
              </RRow>
              <Title3>{entry.score}</Title3>
            </RRow>
          ))}
        </RColumn>
        <Space />
      </div>
    </>
  );
}
